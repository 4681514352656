import './applyPolyfills';

import { CreateClientContextProps, CreateClientPluginContextProps } from '@whop/app/types';
import { createEnv } from '@whys/fetch/lib/browser-env';
import { init, captureException, captureMessage } from '@sentry/browser';

import AppRoot from '../tsx.app.global/AppRoot';
import { containerConfig } from '../app.state';
import routes from '../app.base/routes';
import plugins from '../app.base/plugins';
import { AppContext, AppPluginContext } from '../app.types/state';
import { loadFetchJson } from '../app.state/loadFetchJson';
import { createAsyncRegistryManager } from '../pkg.asyncregistry/registryManager';
import { loadI18n } from '../app.state/loadI18n';
import { loadRuntimeConfig } from '../app.state/loadRuntimeConfig';
import { loadSecondaryPolyfills } from './polyfills';
import { createPreloadResources } from '../app.universal/preloadResources';
import { loadInitialData } from '../app.state/loadInitialData';
import { createAppResourceContext } from '../app.universal/createAppResourceContext';
import { manageResolve } from './onloadState';
import { loadProject } from '../app.state/loadProject';
import { createSentryLogger } from '../app.universal/sentryLogger';
import { AppLanguageEnum } from '../app.types/app';
import { createRuntimeCache } from '../app.universal/createRuntimeCache';

const SentryBrowser = { init, captureException, captureMessage };

function createClientFetchEnv(options: { csrftoken: string; onError: (e: Error) => void }) {
  return createEnv({
    onError: (e: Error) => {
      console.error(e);
    },
    //! Only hotfix, default is 30000
    timeout: 90000,
    init: {
      // (cors is default)
      mode: 'cors',
      headers: {
        'X-CSRFToken': options.csrftoken,
      },
    },
  });
}

async function createPluginContext({
  appCache,
  csrftoken,
  initialData,
}: CreateClientPluginContextProps): Promise<AppPluginContext> {
  const fetchJson = await loadFetchJson({
    fetchEnv: createClientFetchEnv({
      csrftoken,
      onError: (e: Error) => {
        console.error(e);
      },
    }),
  });
  const initialDataValid = await loadInitialData({
    initialDataMaybe: initialData,
    fetchJson,
    appCache,
  });
  return { gtm: { gtmId: initialDataValid.marketing.gtm_id || '' } };
}

async function createContainerContext({
  djreactState,
  appCache,
  csrftoken,
  initialData,
}: CreateClientContextProps): Promise<AppContext> {
  // step: load polyfills (should be run first, other code can depend on it)
  await loadSecondaryPolyfills();

  const environment = djreactState.env;
  const language = djreactState.language as AppLanguageEnum;
  const logger = createSentryLogger(SentryBrowser, {
    dsn: 'https://56a3ccc79d53412c9ca68fcf390ffa84@coffee.whys.eu/17',
    environment,
    release: djreactState.version,
  });
  const fetchEnv = createClientFetchEnv({ csrftoken, onError: logger.logError });
  const fetchJson = await loadFetchJson({ fetchEnv });
  const initialDataValid = await loadInitialData({
    initialDataMaybe: initialData,
    fetchJson,
    appCache,
  });
  const projectId = initialDataValid.site.project_id;
  const project = await loadProject(initialDataValid);
  const i18n = await loadI18n({ language: djreactState.language, project });
  const runtimeConfig = await loadRuntimeConfig({ initialData: initialDataValid });
  const runtimeCache = createRuntimeCache();

  const isDebugMode = djreactState.env !== 'production';
  const resourceContext = createAppResourceContext({
    __appCache: appCache,
    __runtimeCache: runtimeCache,
    __fetchJson: fetchJson,
    __fetchEnv: fetchEnv,
    __i18n: i18n,
    __manageResolve: manageResolve,
    __runtimeConfig: runtimeConfig,
    __isDebug: isDebugMode,
  });

  const promiseManage = manageResolve;

  return {
    projectId,
    project,
    djreactState,
    fetchEnv,
    fetchJson,
    i18n,
    language,
    appCache,
    asyncRegistry: createAsyncRegistryManager(project.asyncRegistry),
    staticRegistry: project.staticRegistry,
    preloadResources: createPreloadResources({
      runtimeConfig,
      locationSearch: window.location.search,
    }),
    logger,
    resourceContext,
    promiseManage,
    runtimeConfig,
    runtimeCache,
    universal: {
      getUrlPath: () => {
        return window.location.pathname || '';
      },
      getUrlSearch: () => {
        return window.location.search || '';
      },
      performRedirect: ({ to }) => {
        window.history.pushState({}, '', to);
      },
    },
    __internal: {
      initialData: initialDataValid,
    },
  };
}

export function createClientConfig() {
  return {
    routes,
    plugins,
    AppRoot,
    containerConfig,
    createContainerContext,
    createPluginContext,
  };
}

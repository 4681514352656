import { ResourceCache, PaginatedResource, PaginationParams, RuntimeResourceCache } from './types';

import { definePlainResource, PlainResourceProps } from './plain';
import { createFlatResourceCache } from './utils';
import { stringifyToStableJson } from '@whop/utils/json';
import { stringifyQueryParams, mergeQueryParamsWithQs } from './queryParams';

export function definePaginatedResource<Resource>(props: {
  resolve: (options: { url: string }) => Promise<{ items: Resource[]; nextPageUrl: string }>;
  cache: ResourceCache<AnyInternalOnly, null>;
  instancesCache: RuntimeResourceCache;
  readFromCache?: PlainResourceProps<Resource[]>['readFromCache'];
  initialUrl: string;
  config: { initialPageSize: number };
  options: PlainResourceProps<Resource>['options'];
}): PaginatedResource<Resource> {
  const { initialUrl, config, options, instancesCache } = props;

  const resolveUrl = (_url: string, _params: PaginationParams) => {
    const resolvedParams = {
      items_count: _params.pageSize,
      page: _params.pageNumber,
      ..._params.extra,
    };

    if (_params.sortBy && _params.sortOrder) {
      resolvedParams['ordering'] = `${_params.sortOrder === 'desc' ? '-' : ''}${_params.sortBy}`;
    }

    if (_url.includes('?')) {
      // handle _url already has querystring
      const [urlWithoutQs, qs] = _url.split('?');
      const mergedQs = mergeQueryParamsWithQs(qs, resolvedParams);
      return `${urlWithoutQs}?${mergedQs}`;
    }
    const query = stringifyQueryParams(resolvedParams);
    return query ? `${_url}?${query}` : _url;
  };

  const urlCache = createFlatResourceCache<AnyInternalOnly>(props.cache, `${initialUrl}_url`);

  const paginated = (paginateParams: PaginationParams) => {
    const resourceKey = initialUrl + stringifyToStableJson(paginateParams);
    const existing = instancesCache.getValue(resourceKey, null);
    if (existing) {
      return existing;
    }
    const cache = createFlatResourceCache<AnyInternalOnly>(props.cache, resourceKey);

    const getNextUrl = () => {
      return urlCache.getValue(null) || '';
    };

    const getCurrentUrl = () => {
      return urlCache.getValue(null) || ''; // fallback to initialUrl?
    };

    const setNextUrl = (url: string) => {
      urlCache.setValue(url);
    };

    const asPlain = definePlainResource<Resource[], Resource[]>({
      name: getCurrentUrl(),
      cache,
      readFromCache: props.readFromCache,
      options,
      fallbackValue: [],
      resolve: async () => {
        const { items, nextPageUrl } = await props.resolve({
          url: resolveUrl(initialUrl, paginateParams),
        });
        setNextUrl(nextPageUrl);
        return items;
      },
    });
    const resource = {
      ...asPlain,
      fetchNext: async () => {
        const url = resolveUrl(getNextUrl(), {
          ...paginateParams,
          // pageNumber: paginateParams.pageNumber + 1,
        });
        const { nextPageUrl, items } = await props.resolve({ url });
        const hasNext = !!nextPageUrl;

        setNextUrl(
          hasNext
            ? nextPageUrl
            : // Initial state which makes the resource reloadable
              resolveUrl(initialUrl, { ...paginateParams, pageNumber: 1 })
        );

        return { items, hasNext };
      },

      hasNext: () => {
        const nextUrl = getNextUrl();
        return !!nextUrl;
      },
      config,
    };
    instancesCache.setValue(resourceKey, resource);
    return resource as AnyInternalOnly;
  };

  paginated.config = config;

  return paginated;
}

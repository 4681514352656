import { models } from '@whop/core/types';
import { CartStatsPayload } from '@whop/cart/types';
import { InitialDataPayload } from '@whop/core/types.initialdata';
import { UserPermissions } from '@whop/user/types';
import { OVERRIDE_PROJECT_ID, OVERRIDE_THEME_ID } from './DO_NOT_COMMIT';

const defaultProjectId = 'starter';
const defaultThemeId = 'default';

// @review: is it worth it to remap everything?
export type InitialDataModel = {
  site: InitialDataPayload['site'];

  static_pages: InitialDataPayload['static_pages'];
  system_pages: InitialDataPayload['system_pages'];
  operator: InitialDataPayload['operator'];

  available_languages: InitialDataPayload['available_languages'];

  // session: SessionType,
  permissions: UserPermissions;

  profile: InitialDataPayload['profile'];
  marketing: InitialDataPayload['marketing'];

  config: InitialDataPayload['config'];

  softwaremodules: InitialDataPayload['softwaremodules'];

  frontendCustom: {
    cartStats: models.OptionalField<CartStatsPayload>;
    favoritesCount: InitialDataPayload['header_data']['favorites_count'];
  };
  // currency: string,
};

// @review: good idea? app won't run anyway...
export const defaultInitialData: InitialDataModel = {
  // cart: { total: 0, itemsCount: 0 },
  // watched: 0,
  // favorites: 0,
  // defaultLanguage: 'en',
  site: {
    name: '',
    domain: '',
    claim: '',
    page_title: '',
    project_id: defaultProjectId,
    theme_id: defaultThemeId,
  },
  // rules: {
  //   show_newsletter_form: true,
  // },
  operator: {
    name: '',
    identification_number: '',
    vat_number: '',
    international_vat_id: '',
    address_street: '',
    address_city: '',
    address_zipcode: '',
    bank_account_number: '',
    bank_iban: '',
    bank_swift: '',
    bank_name: '',
    country: '',
    opening_hours: '',
    email: '',
    email_note: '',
    primary_phone_number: '',
    alternative_phone_numbers: [],
    mobile_number: '',
    fax_number: '',
  },
  available_languages: [],
  marketing: {
    gtm_id: '',
  },
  static_pages: {},
  config: {
    pagination: {
      default_page_size: 20,
      default_page_sizes: {},
    },
    partner_id_prices: false,
    price: {
      currency: 'EUR',
      locale: 'en-GB',
      minimum_fraction_digits: 0,
      maximum_fraction_digits: 2,
    },
    registration: {
      registration_mode: 'all',
      default_active_registered_user: true,
    },
  },
  system_pages: {},
  // session: defaultSessionState,
  permissions: {},
  profile: null,
  // currency: 'CZK',
  // footerData: [],
  frontendCustom: { cartStats: null, favoritesCount: 0 },
  softwaremodules: [],
};

export function mapInitialData(payload: InitialDataPayload): InitialDataModel {
  const { permissions } = payload;

  // @todo: remove theme_id if always sent in API
  const site = { ...payload.site, theme_id: payload.site.theme_id || defaultThemeId };

  // step: this enables HMR-enabled change of project/theme
  if (OVERRIDE_THEME_ID) {
    site.theme_id = OVERRIDE_THEME_ID;
  }
  if (OVERRIDE_PROJECT_ID) {
    site.project_id = OVERRIDE_PROJECT_ID;
  }

  return {
    ...payload,
    site,
    frontendCustom: {
      cartStats: payload.header_data.cart,
      favoritesCount: payload.header_data.favorites_count,
    },
    permissions: {
      ...permissions?.read_permissions,
      ...permissions?.create_permissions,
      ...permissions?.update_permissions,
      ...permissions?.delete_permissions,
      ...permissions?.display_permissions,
    },
  };
}

import { ProfileModel } from './types';

export const defaultProfile: ProfileModel = {
  id: '',
  title: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  mobileNumber: '',
  customerId: '',
  email: '',
};

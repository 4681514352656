import * as React from 'react';
import { createAppRoot } from '@whys/app/lib/pages';

import { AppContainer, ResetAppFn } from '../app.types/state';

import { AppLayout } from './AppLayout';
import { AppContext } from './AppContext';
import { AppDocument } from './AppDocument';
import { AppBehavior } from './AppBehavior';
import { PageErrorBoundary, GlobalErrorBoundary } from './error-boundaries';

// registry
import { AsyncRegistryProvider } from '../pkg.asyncregistry/context';
import { StaticRegistryProvider } from '../pkg.syncregistry/context';
import { AsyncRegistryManager } from '../pkg.asyncregistry/types';
import { declareUsedComponents } from '../pkg.asyncregistry';
import { AsyncRegistry } from '../pkg.asyncregistry/AsyncRegistry';

// state
import { I18nType } from '../app.types/npm';

// state containers
import { createLoginContainer } from '../app.state/createLoginContainer';
import { createInitialData } from '../app.state/createInitialData';
import { createConfigContainer } from '../app.state/createConfigContainer';
import { createGlobalContainer } from '../app.state/createGlobalContainer';
import { createStarterContainer } from '../app.state/createStarterContainer';
import { createCartContainer } from '../app.state/createCartContainer';
// else
import { InlineDotLoader } from '../tsx.loaders/InlineDotLoader';
import { _IS_SERVER_INTERNAL } from '../app.constants/runtime';
import { StaticRegistryModel } from '../pkg.syncregistry/types';
import { createMenuContainer } from '../app.state/createMenuContainer';
import { createFavoritesContainer } from '../app.state/createFavoritesContainer';
import { createRegistryContainer } from '../app.state/createRegistryContainer';
import { createMultiInsertContainer } from '../app.state/createMultiInsertContainer';
import { PagesContextValue } from '../app.context/pagesContext';
import { createPageContainer } from '../app.state/createPageContainer';

const usedComponents = declareUsedComponents({
  footerView: 'global.footerView',
  headerView: 'global.headerView',
});

type AppRootContext = { appContainer: AppContainer };

type InitialProps = {
  i18n: I18nType;
  asyncRegistry: AsyncRegistryManager;
  staticRegistry: StaticRegistryModel;
  appContainer: AppContainer;
  footerViewProps: object;
  headerViewProps: object;
  pagesContext: PagesContextValue;
};

function ClientOnlySuspense(props: { children: React.ReactElement }) {
  if (_IS_SERVER_INTERNAL) {
    return props.children;
  }
  return <React.Suspense fallback={<InlineDotLoader />}>{props.children}</React.Suspense>;
}

// just a helper to mark subtree that has actual HTML content/DOM elements
const HtmlSubtree = React.Fragment;

function AppRoot(
  initialProps: InitialProps & {
    children: React.ReactElement;
  }
) {
  const [props, setProps] = React.useState(initialProps);

  const { appContainer } = props;

  const onResetApp: ResetAppFn = (options) => {
    const updateContext = options?.updateContext || {};
    // step: clear all cached data
    appContainer.context.appCache.clearAll();
    // step: clear resolved instances
    appContainer.clearAll();
    // step: clear all runtime cache (used for resources)
    appContainer.context.runtimeCache.clearAll();

    // step: update context simply via "mutability"
    for (const key of Object.keys(updateContext)) {
      const val = updateContext[key];
      appContainer.context[key] = val;
    }

    getInitialProps({ appContainer }).then((newProps) => {
      setProps({ ...newProps, children: props.children });
    });
  };

  const resetOnError = () => {
    onResetApp({});
  };

  const { djreactState, logger } = appContainer.context;
  return (
    <GlobalErrorBoundary onReset={resetOnError} logger={logger} environment={djreactState.env}>
      <ClientOnlySuspense>
        <AppContext
          appContainer={props.appContainer}
          i18n={props.i18n}
          onResetApp={onResetApp}
          pagesContext={props.pagesContext}
        >
          <StaticRegistryProvider value={props.staticRegistry}>
            <AsyncRegistryProvider value={props.asyncRegistry} fallback={InlineDotLoader}>
              <HtmlSubtree>
                <AppDocument />
                <AppLayout
                  header={
                    <AsyncRegistry use={usedComponents.headerView} {...props.headerViewProps} />
                  }
                  body={
                    <AppBehavior>
                      <PageErrorBoundary
                        onReset={resetOnError}
                        logger={logger}
                        environment={djreactState.env}
                      >
                        {props.children}
                      </PageErrorBoundary>
                    </AppBehavior>
                  }
                  footer={
                    <AsyncRegistry use={usedComponents.footerView} {...props.footerViewProps} />
                  }
                />
              </HtmlSubtree>
            </AsyncRegistryProvider>
          </StaticRegistryProvider>
        </AppContext>
      </ClientOnlySuspense>
    </GlobalErrorBoundary>
  );
}

async function getInitialProps(ctx: AppRootContext): Promise<InitialProps> {
  const { appContainer } = ctx;
  const { asyncRegistry, staticRegistry, preloadResources, i18n, universal } = appContainer.context;

  // step: firstly resolve initial data which is used on many places (Promise.all is concurrent)
  await appContainer.resolveOnce(createInitialData);
  await appContainer.resolveOnce(createConfigContainer);

  // step: resolve "global" containers
  const loginContainer = await appContainer.resolveOnce(createLoginContainer);
  const globalContainer = await appContainer.resolveOnce(createGlobalContainer);
  const cartContainer = await appContainer.resolveOnce(createCartContainer);
  const pageContainer = await appContainer.resolveOnce(createPageContainer);

  await appContainer.resolveOnce(createStarterContainer);
  await appContainer.resolveOnce(createRegistryContainer);
  // @temp: create in an advance because otherwise we have race conditions from AppFooterView vs AppHeaderView
  await appContainer.resolveOnce(createMenuContainer);
  // @temp: remove this once you solve loading containers that can be used very deeply down
  // the component tree.
  await appContainer.resolveOnce(createFavoritesContainer);
  await appContainer.resolveOnce(createMultiInsertContainer);

  const preloadUrl = async (options: { pathname: string; search: string }) => {
    // note: we can patch the context and set an explicit url
    await pageContainer.prefetchPage({
      ...options,
      ctx: {
        pageContainer,
        appContainer,
      },
    });
  };

  // step: preload current page
  const pageInitialPropsPromise = preloadUrl({
    pathname: universal.getUrlPath(),
    search: universal.getUrlSearch(),
  });

  const isLogged = loginContainer.isLogged();

  //
  // preload (do not await!) >>>
  //

  let resourcesToPreloadOnly = [];

  if (globalContainer.hasCustomPermission('xReadCart')) {
    // Note: we only want to load the resource if it wasn't already loaded (it should be
    // from initial data). We do not want re-fetch like when user visit cart page because
    // the data from initial data are fresh during initial render.
    resourcesToPreloadOnly.push(cartContainer.cartItems);
  }
  if (isLogged) {
    resourcesToPreloadOnly.push(loginContainer.profile);
  }

  // preload, do not wait
  preloadResources(...resourcesToPreloadOnly);

  //
  // <<< preload
  //

  // preload global components
  const registryPromise = asyncRegistry.preload(
    'header.logo',
    // on homepage
    'product.card',
    'article.card',
    // on cart page
    'cart.item'
  );

  const registryDeclaredPromise = asyncRegistry.preloadDeclared(usedComponents).then(async () => {
    const footerPromise = asyncRegistry
      .fetch(usedComponents.footerView)
      .then((view) => view.getInitialProps(ctx));
    const headerPromise = asyncRegistry
      .fetch(usedComponents.headerView)
      .then((view) => view.getInitialProps(ctx));
    const [footerViewProps, headerViewProps] = await Promise.all([footerPromise, headerPromise]);
    return { footerViewProps, headerViewProps };
  });

  // step: the main await for all server requests (load load each concurrently)
  await Promise.all([pageInitialPropsPromise, registryPromise, registryDeclaredPromise]);

  // Note(await): it should already be loaded (see above)
  const { footerViewProps, headerViewProps } = await registryDeclaredPromise;

  return {
    i18n,
    asyncRegistry,
    staticRegistry,
    appContainer,
    footerViewProps,
    headerViewProps,
    pagesContext: {
      preloadUrl,
      niceUrlInfos: pageContainer.niceUrlInfos,
      selectSystemComponent: (name) => pageContainer.selectSystemPageComponent(name),
      selectNiceUrlComponent: (name) => pageContainer.selectNiceUrlComponent(name),
    },
  };
}

export default createAppRoot({
  getInitialProps,
  Component: AppRoot,
});

import { Container } from '@whys/app/lib/state';
import {
  AppResourceContext,
  definePlainAppNullableResource,
} from '../tmp.prototyping/appLevelResources';
import { ProductContainerType } from './ProductContainer';
import { PlainResource } from '@whop/resources/types';
import { CartItemViewModel } from '../app.tsx.types/cart';
import { CartDetailModel, CartDetailPayload, CartStatsModel } from '@whop/cart/types';
import { httpResources, mapCartDetail } from '@whop/cart';
import { definePlainResource } from '@whop/resources/plain';
import { createFlatResourceCache } from '@whop/resources/utils';
import {
  AsyncTaskCreator,
  AsyncParamTaskCreator,
  defineParamTaskFromResource,
  definePlainTaskFromResource,
  CustomParamTaskCreator,
  CustomTaskCreator,
} from '../tmp.prototyping/asyncTasks';
import { models } from '@whop/core/types';
import { defineSelector } from '../tmp.prototyping/selector';
import { doFetch } from '@whys/fetch/lib/fetch';
import { definePlainDerivedResource } from '../tmp.prototyping/derivedResources';
import { ShippingPriceViewModel } from '../pkg.cart/types';
import { CommonError } from '@whop/backend/types';

const emptyStats = {
  total: 0,
  itemsCount: 0,
};

type LocalState = {
  forceUpdateInc: number;
  statsStatus: 'idle' | 'loading';
  cartError: { status: string | undefined; message: string | undefined };
};
type LocalProps = {
  productContainer: ProductContainerType;
  resourceContext: AppResourceContext;
  initialStats?: CartStatsModel;
};

type WithCommonUserErrorPayload<T extends {}> = T & {
  error: null | undefined | CommonError;
};

type UpdateItemAsyncTask = {
  run: () => Promise<
    | { ok: true; status: 'ok' }
    | { ok: false; status: 'error'; errorMessage: string; errorKey: string }
    | { ok: false; status: 'crash' }
  >;
};

type BulkImportErrorPayload = WithCommonUserErrorPayload<{}>[] | undefined;
export type BulkImportReport = {
  user_message: string;
  code: string;
  detail: string;
};
type BulkImportAsyncTask = {
  run: () => Promise<{
    ok: boolean;
    errors: BulkImportErrorPayload;
    reports: BulkImportReport[];
  }>;
};

type AddItemAsyncTask = UpdateItemAsyncTask; // its same for now

export class CartContainer extends Container<LocalState> {
  private state: LocalState = {
    forceUpdateInc: 0,
    statsStatus: 'idle',
    cartError: {
      status: '',
      message: '',
    },
  };

  // resources
  cartDetail: PlainResource<CartDetailModel, null>;
  cartItems: PlainResource<CartItemViewModel[]>;
  missingCartItems: PlainResource<CartItemViewModel[]>;
  cartStats: PlainResource<CartStatsModel>;

  // tasks
  addItemAlt: CustomTaskCreator<{ variant: string; quantity: number }, AddItemAsyncTask>;
  clearItems: AsyncTaskCreator<{}>;
  setItemQuantityAlt: CustomParamTaskCreator<[string], { quantity: number }, UpdateItemAsyncTask>;
  removeItem: AsyncParamTaskCreator<[models.FrontendId], {}>;
  bulkImport: CustomTaskCreator<File[], BulkImportAsyncTask>;

  // selectors
  select = defineSelector({
    // total: () => {
    //   const cartDetail = this.cartDetail.select();
    //   return cartDetail?.prices.total_discount_price_gross ?? 0;
    // },
    // itemsCount: () => {
    //   const cartDetail = this.cartDetail.select();
    //   if (cartDetail) {
    //     return cartDetail.items.length;
    //   }
    //   return 0;
    // },
    status: () => this.state.statsStatus,
  });

  constructor(private props: LocalProps) {
    super();

    const { resourceContext, productContainer } = props;

    //
    // Resources
    //

    this.cartDetail = definePlainAppNullableResource(httpResources.cartDetail, {
      resourceContext,
      map: (payload: CartDetailPayload) => {
        if (!this.state.cartError?.message) {
          this.setState({
            cartError: { message: payload.error?.user_message, status: payload.error?.code },
          });
        }

        return mapCartDetail(payload);
      },
    });

    this.cartStats = definePlainDerivedResource<CartStatsModel, CartDetailModel>({
      key: 'cartStats',
      fallback: emptyStats,
      resourceContext,
      derivesFrom: this.cartDetail,
      mapTo: (cartDetail) => {
        return {
          total:
            cartDetail.prices.rounded_total_discount_price_gross?.rounded_price ||
            cartDetail.prices.total_discount_price_gross ||
            0,
          itemsCount: cartDetail.itemsCount,
        };
      },
    });

    this.cartItems = (() => {
      const key = 'cart.items';
      const plainCache = resourceContext.__appCache.getOrCreateCache<AnyInternalOnly>(key);
      const cache = createFlatResourceCache(plainCache, key);
      return definePlainResource({
        cache,
        name: key,
        dependencies: [this.cartDetail],
        fallbackValue: [],
        resolve: async () => {
          const detailResource = await this.cartDetail.getOrFetch();
          if (!detailResource) {
            return [];
          }
          const ids = detailResource.items.map((_) => _.variantId);
          const productsById = await productContainer.resolveProductsToMap(ids);
          return detailResource.items
            .map((item) => {
              const product = productsById.get(item.variantId);
              if (!product) {
                return null;
              }
              return { ...item, product };
            })
            .filter(Boolean);
        },
        options: resourceContext,
      });
    })();

    this.missingCartItems = (() => {
      const key = 'cart.missingItems';
      const plainCache = resourceContext.__appCache.getOrCreateCache<AnyInternalOnly>(key);
      const cache = createFlatResourceCache(plainCache, key);
      return definePlainResource({
        cache,
        name: key,
        dependencies: [this.cartDetail],
        fallbackValue: [],
        resolve: async () => {
          const detailResource = await this.cartDetail.getOrFetch();
          if (!detailResource) {
            return [];
          }
          const currentMissingItems = this.missingCartItems.select();
          if (!detailResource.missing_items?.length) {
            return currentMissingItems;
          }

          const ids = detailResource.missing_items.map((_) => _.variantId);
          const productsById = await productContainer.resolveProductsToMap(ids);
          return detailResource.missing_items
            .map((item) => {
              const product = productsById.get(item.variantId);
              if (!product) {
                return null;
              }
              return { ...item, product };
            })
            .filter(Boolean);
        },
        options: resourceContext,
      });
    })();

    //
    // Tasks
    //

    this.clearItems = definePlainTaskFromResource<{}>(httpResources.clearCart, {
      taskId: 'clearItems',
      resourceContext: this.props.resourceContext,
      __runBefore: async () => {
        this.setState({
          statsStatus: 'loading',
        });
      },
      __runAfter: async () => {
        await this.refetchFullCart();
        this.setState({ statsStatus: 'idle' });
      },
    });

    this.addItemAlt = (data: { variant: string; quantity: number }) => {
      return {
        run: async () => {
          this.setState({
            statsStatus: 'loading',
          });

          type NoResponsePayload = {};
          type ResponsePayload = WithCommonUserErrorPayload<{}>;
          type RequestPayload = { variant: string; quantity: number };

          const result = await resourceContext.__fetchJson<
            NoResponsePayload,
            ResponsePayload,
            RequestPayload
          >(httpResources.addItem, data);

          await this.refetchFullCart();
          this.setState({ statsStatus: 'idle' });

          if (result.status === 'ok') {
            return { ok: true, status: 'ok' };
          }
          if (result.status === 'error') {
            const { data } = result;
            return {
              ok: false,
              status: 'error',
              errorMessage: data?.error?.user_message || '',
              errorKey: data?.error?.code || '',
            };
          }
          return { ok: false, status: 'crash' };
        },
      };
    };

    this.setItemQuantityAlt = (id: models.FrontendId) => {
      return (data: { quantity: number }) => {
        return {
          run: async () => {
            this.setState({
              statsStatus: 'loading',
            });

            type NoResponsePayload = {};
            type ResponsePayload = WithCommonUserErrorPayload<{}>;
            type RequestPayload = { quantity: number };

            const result = await resourceContext.__fetchJson<
              NoResponsePayload,
              ResponsePayload,
              RequestPayload
            >(httpResources.setItemQuantity(id), data);

            await this.refetchFullCart();
            this.setState({ statsStatus: 'idle' });

            if (result.status === 'ok') {
              return { ok: true, status: 'ok' };
            }
            if (result.status === 'error') {
              const { data } = result;
              return {
                ok: false,
                status: 'error',
                errorMessage: data?.error?.user_message || '',
                errorKey: data?.error?.code || '',
              };
            }
            return { ok: false, status: 'crash' };
          },
        };
      };
    };

    this.removeItem = defineParamTaskFromResource<[string], {}>(httpResources.removeItem, {
      resourceContext: this.props.resourceContext,
      getTaskId: (id: models.FrontendId) => `removeItem(variantId=${id})`,
      __runBefore: async () => {
        this.setState({
          statsStatus: 'loading',
        });
      },
      __runAfter: async () => {
        await this.refetchFullCart();
        this.setState({ statsStatus: 'idle' });
      },
    });

    this.bulkImport = (source: File[]) => {
      return {
        getTaskId: () => 'bulkImport',
        run: async () => {
          const { didOk, errors, reports } = await this._doBulkImport(source);
          await this.refetchFullCart();
          return { ok: didOk, errors: errors, reports: reports };
        },
      };
    };
  }

  async refetchFullCart() {
    this.setState({ statsStatus: 'loading' });
    await this.cartDetail.reload();
    this.setState({ statsStatus: 'idle' });
  }

  async _doBulkImport(files: File[]) {
    let data = new FormData();
    for (const file of files) {
      data.append('files', file, file.name);
    }
    const { __fetchEnv } = this.props.resourceContext;
    const { url, method } = httpResources.bulkImport;

    const response = await doFetch(url, __fetchEnv, {
      method,
      body: data,
    });

    if (response?.status === 500) {
      return { didOk: false };
    }

    const jsonResponse = await response?.json();

    let jsonErrors;
    if (response?.status === 400) {
      jsonErrors = jsonResponse;
    }

    let jsonReports = [];
    if (response?.status === 200 && jsonResponse?.reports) {
      jsonReports = jsonResponse.reports;
    }

    return { didOk: !!(response && response.ok), errors: jsonErrors, reports: jsonReports };
  }

  isCartPending() {
    return this.state.statsStatus === 'loading';
  }

  selectAmountInCart(options: { variantId: string }): number | undefined {
    const items = this.cartItems.select();
    const itemInCart = items.find((value) => value.product.id === options.variantId);
    if (itemInCart) {
      // return itemInCart.quantity;
      const specificItems = items.filter((item) => item.variantId === itemInCart.variantId);

      const sumQuantity = specificItems.reduce((acc, specificItem) => {
        return acc + specificItem.quantity;
      }, 0);

      return sumQuantity;
    }
    return undefined;
  }

  isProductInCart(variantId: string): boolean {
    const itemsInCart = this.cartItems.select();
    return itemsInCart.some((item) => item.variantId === variantId);
  }

  async getCartItemsByIds(ids: string[]): Promise<CartItemViewModel[]> {
    const byIdsFilter = () => {
      let filterIds = new Set(ids);
      return (item: CartItemViewModel) => {
        return filterIds.has(item.variantId);
      };
    };
    const items = await this.cartItems.getOrFetch();
    return items.filter(byIdsFilter());
  }

  _forceUpdate() {
    this.setState({ forceUpdateInc: this.state.forceUpdateInc + 1 });
  }

  calculateVatPrice(item: CartItemViewModel): number | undefined {
    const vat =
      item.prices.total_discount_price_gross && item.prices.total_discount_price_net
        ? item.prices.total_discount_price_gross - item.prices.total_discount_price_net
        : undefined;
    return vat;
  }

  selectShippingPrice(): ShippingPriceViewModel {
    const detail = this.cartDetail.select();
    if (!detail) {
      return { type: 'priced', priceFrom: 0, priceWithVat: 0 };
    }

    switch (detail.freeShippingType) {
      case 'individual':
        return {
          type: 'calculated_after',
        };

      case 'never_paid':
        return {
          type: 'free',
        };

      case 'free_from':
        if (detail.freeShippingRemaining === 0)
          return {
            type: 'free',
          };
        break;
    }

    // implicitly freeShippingType === 'always_paid'
    return {
      type: 'priced',
      priceFrom: detail.shippingPriceFrom,
      priceWithVat: detail.shippingMethod?.price_with_vat,
    };
  }

  selectShippingIsFree() {
    return this.selectShippingPrice().type === 'free';
  }

  selectCartError() {
    return this.state.cartError;
  }

  clearCartError() {
    this.setState({
      cartError: { message: '', status: '' },
    });
  }
}

import { BreakpointsType, AppTheme } from './types';
import { SystemThemeSpec } from '@whop/system';

function createBreakpoints(input: [number, number, number, number]): BreakpointsType {
  let result: AnyInternalOnly = [...input];
  result.xs = 0;
  result.sm = input[0];
  result.md = input[1];
  result.lg = input[2];
  result.xl = input[3];
  return result;
}

function createSpace(): AppTheme['space'] {
  return {
    0: 0,
    1: 8,
    2: 16,
    3: 32,
    4: 64,
    5: 128,
    quarter: 2,
    half: 4,
    length: 5,
  };
}

const breakpoints = createBreakpoints([600, 960, 1280, 1600]);

const primary = '#ccc';

const headingFontSizes = {
  'h1.small': 24,
  'h1.base': 24,
  'h1.large': 24,
  // h2
  'h2.small': 20,
  'h2.base': 20,
  'h2.large': 20,
  // h3
  'h3.small': 16,
  'h3.base': 16,
  'h3.large': 16,
};

// The numeric values are on purpose to demonstrate that each theme can set its own
// specific value based on used font-family.
// The values 700-400-100 are based on https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight,
// should behave like respective literal keywords.
const fontWeights = { normal: 400, bold: 700, light: 100 };

const baseFontFamily = [
  'Nunito Sans',
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'sans-serif',
].join(',');

function createDefaultTheme(): AppTheme {
  const colors = {
    primary,
    primary_contrast: '#fff',
    primary_bg_100: primary,
    primary_bg_300: primary,
    primary_700: primary,
    primary_900: primary,

    secondary: primary,
    secondary_bg: primary,

    //
    hairline: '#5C656F',
    text_hairline: '#5C656F',
    border_hairline: '#ececec',

    error: '#F44336',
    success: '#6fc76a',
    //
    text: '#000000',
    text_contrast: '#FFFFFF',

    bg_body: '#FFFFFF',
    bg_disabled: '#CCCCCC',
    bg_editable: '#fbfbfb',

    // Alternative API inspired by braid (https://seek-oss.github.io/braid-design-system/foundations/tones)
    tone_critical: '#F44336',
    tone_caution: '#ce3800',
    tone_positive: '#6fc76a',
    tone_neutral: '#737272',
    tone_info: '#2c88f7',
    tone_promote: '#855be5',
    // @review better API?
    tone_critical_bg: '',
    tone_caution_bg: '#fffbeb',
    tone_positive_bg: '#d5f8d3',
    tone_neutral_bg: '#f8f8f8',
    tone_info_bg: '',
    tone_promote_bg: '',
  };

  return {
    colors,
    // NotePrototype(colorVariants): deprecated
    colorVariants: {
      primary: {
        lighter: '',
        darker: '',
        contrast: '#000000',
      },
    },
    category: {
      showCategoryBanner: true,
    },
    namedColors: {
      buyAction: primary,
      makeOrderAction: primary,
      labelBg: primary,
      labelTxt: colors.text_contrast,
    },
    space: createSpace(),
    // fontSizes: [12, 14, 16, 20, 24],
    fontSizes: {
      0: 12,
      1: 14,
      2: 16,
      3: 20,
      4: 24,
      'text.base': 16,
      'text.small': 11,
      'text.large': 20,
      ...headingFontSizes,
    },
    headingFontSizes,
    fontWeights,
    textFontSizes: { small: 11, base: 16, large: 20 },
    fonts: {
      base: baseFontFamily,
      headings: baseFontFamily,
      materialUi: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    breakpoints,
    override: {
      html: {},
      globals: {},
      globalStyles: {},
    },
    meta: {
      name: 'main',
    },
    // TODO: menu orientation
    layout: {
      variant: 'default',
    },
  };
}

const defaultTheme = createDefaultTheme();

// Note: just verify that the theme adheres to SystemThemeSpec
function verifyContract<_T>(_val: _T) {}
verifyContract<SystemThemeSpec>(defaultTheme);

export default defaultTheme;
